<template>
  <div class="formWrapper">
    <el-form ref="dataForm" :model="dataForm" :rules="dataRule" @keyup.enter.native="dataFormSubmit()"
             status-icon>
      <el-row :gutter="25">
        <el-col :span="12">
          <el-form-item label-width="60px" label="姓名" prop="name">
            <el-input v-model="dataForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="90px" label="身份证号码" prop="idCard">
            <el-input v-model="dataForm.idCard"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label-width="60px" label="备注" prop="remarks">
            <el-input v-model="dataForm.remarks" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="section-title-row">
        <div class="title">附件信息</div>
        <div class="line"></div>
      </div>
      <el-row>
        <el-col :span="16">
          <image-uploader
            @add="addImage"
            @remove="removeImage"
            :images="imageList"
            no-title
          ></image-uploader>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button class="submit-button" type="primary" @click="dataFormSubmit()">保存</el-button>
        <el-button class="submit-button" type="warning" @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ImageUploader from '@/components/image-uploader'

export default {
  name: 'person-black-update',

  components: { ImageUploader },

  data () {
    return {
      loading: false,
      id: '',
      dataForm: {},
      imageList: []
    }
  },
  created () {
    this.init()
  },

  methods: {
    async init () {
      this.id = this.$route.query.id
      this.loading = true
      if (this.id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/user/black/getById'),
          method: 'post',
          data: this.id
        })
        if (data && data.code === 200) {
          const temp = data.datas
          if (temp.images) {
            this.imageList = temp.images.split(',').map(j => ({ url: '/files' + j }))
          } else {
            this.imageList = []
          }

          this.dataForm = temp
          this.loading = false
          console.log(this.imageList)
        }
      } else {
        this.dataForm = {
          id: '',
          name: '',
          idCard: '',
          remarks: ''
        }
        this.id = ''
        this.imageList = []
        this.loading = false
      }
    },

    addImage (path) {
      this.imageList.push(path)
    },

    removeImage (url) {
      this.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    cancel () {
      this.$confirm('确认取消编辑吗？未保存的更改将被重置', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({ name: this.$route.name.split('-update')[0] })
      }).catch(() => { })
    },

    dataFormSubmit () {
      this.$http({
        url: this.$http.adornUrl('/user/queryByIdCard'),
        method: 'post',
        data: this.dataForm.idCard
      }).then((data) => {
        if (data && data.code === 200) {
          const temp = data.datas
          if (temp) {
            this.$confirm('用户已经绑定车辆 [' + temp + ']，确定黑名单操作后，黑名单人员将从驾操人员中删除，车辆将变更为待审核状态，请知会相关人员更新 [' + temp + '] 的驾操人员信息并审核信息。', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.dataRequest()
            }).catch(() => { })
          } else {
            console.log('0000000000')
            this.dataRequest()
          }

          this.loading = false
        }
      })
    },
    dataRequest () {
      this.dataForm.images = this.imageList.join(',')
      let url = '/user/black/save'
      if (this.id) {
        url = '/user/black/update'
      }
      console.log(url)
      this.$http({
        // url: '/bengche' + url,
        url: this.$http.adornUrl(url),
        method: 'post',
        data: this.dataForm
      }).then((data) => {
        if (data && data.code === 200) {
          this.$message.success('操作成功')
          this.$router.push({ name: this.$route.name.split('-update')[0] })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .formWrapper {
      width: 90%;
      margin: 20px 0 0 5%;
  }
</style>
